<template>
  <div class="containerStyle">

    <v-row justify="center"><v-dialog v-model="dialog" max-width="800px" min-height="800px">
        <v-card>
          <v-card-text>
            <div v-if="!loadLogin" class="form-container">
              <v-col>
                <br>
                <br>
                <h1 class="titulo text-center"> Inicia tu sesión</h1><br>
                <h2 class="text-center"> Ingresa tu rut y contraseña de Puntos Cencosud</h2><br>
                <br><label>Rut</label>
                <v-form ref="form" v-model="valid">
                  <v-text-field v-model="newRut2" :rules="rutRules2" label="Ingresa tu rut" required filled solo
                    hide-details="auto" @input="changeRut2">
                  </v-text-field><br><label>Contraseña</label>
                  <v-text-field v-model="contrasenaCliente" :rules="contrasenaRules" label="Ingresa tu contraseña"
                    required filled solo hide-details="auto" type="password">
                  </v-text-field>
                </v-form>
                <br><v-btn class="login" @click="logincenco()"> Iniciar sesión </v-btn><br>
                <br><a
                  style="color: rgb(108, 117, 125); text-decoration: none; background-color: transparent;  justify-content: center;font-family: 'Nunito Sans', sans-serif;"
                  href="https://www.puntoscencosud.cl/puntos/recuperar">Quiero recuperar mi contraseña de Puntos
                  Cencosud</a>
              </v-col>
              <v-col v-if="screen > 800">
                <v-img contain :src="cencoLogin" />
              </v-col>
            </div>
            <div v-if="loadLogin" class="text-center">
              <br>
              <br>
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>

    <v-container>
      <!-- <div :class="bannerView">BANNER SEGURO OBLIGATORIO SOAP 2024</div> -->
      <div :class="bannerView"></div>
      <br>
      <div v-if="e1 != 3 && e1 != 2" :class="cartBoxView">
        <v-btn @click="redirectFindSoap()" :class="btnFindSoapView" id="volver_2">CONSULTA TU SOAP</v-btn>
        <CarritoCompra id="home_2" :class="btnCartButtonView" :patentes="patentesCarrito" :e1="e1"></CarritoCompra>
      </div>

      <div v-else :class="cartBoxView">
        <v-btn @click="redirectFindSoap()" :class="btnFindSoapView3" id="volver_5">CONSULTA TU SOAP</v-btn>
      </div>



      <br v-if="screen <= 800">
      <v-stepper alt-labels v-model="e1" elevation="0" :class="steps">
        <v-stepper-header :class="stepperHeaderView">

          <v-stepper-step :complete="e1 > 1" step="1"><b :class="stepLabelSelected">Revisa tus datos</b></v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step :complete="e1 > 2" step="2" class="stepLabel"><b
              :class="stepLabelSelected">Pagar</b></v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3" class="stepLabel"><b :class="stepLabelSelected">Recibe e imprime tu
              SOAP</b></v-stepper-step>

        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1">
            <div v-if="loadSoap" class="text-center">
              <br>
              <br>
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>

            <div v-else>

              <InitForm v-if="loadSoap == false" :carData="carData" :propietarioData="propietarioData" :newCar="newCar" />

            </div>

          </v-stepper-content>

          <v-stepper-content step="2">
            <div v-if="loading" class="text-center">
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>

            <SecForm v-else @getToken="getToken" />

          </v-stepper-content>

          <v-stepper-content step="3">
            <div v-if="loading || loadingFinal" class="text-center">
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br><br>
              Estamos procesando tu cotización. . .
            </div>

            <ThirdForm v-else-if="!loading && !loadingFinal" />

            <br>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>

      <br><br>

      <div v-if="e1 == 2 && conditions == true && screen > 800" class="finalPayContainerDesktop">

        <br>
        <br>

        <div style="display: flex; justify-content: center; align-items: center;">
          <b class="opcionPago"> Puedes pagar tu SOAP con: </b>
        </div>

        <div v-if="typePlan == 'soap-clientes-cenco' && this.patentesCarrito2.length == 1"
          style="justify-content: center; align-items: center; margin: auto; width: 100%; max-width: 650px;">
          <div style="justify-content: center; align-items: center; padding-left: 17%;">
            <v-col style="margin-bottom:-8%">
              <v-radio-group v-model="selectedPaymentMethod" @change="handlePaymentMethodChange()" row>
                <v-radio :value="'puntos'" style="margin-bottom: 20px;">
                  <template v-slot:label>
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                      <label style="margin:auto;">Puntos Cencosud</label>
                      <img width="130px" height="130px" :src="PuntosLogoClientes" class="payment-icon" />
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="'tarjeta'" :disabled="paymentMethodDisabled" style="margin-bottom: 20px;">
                  <template v-slot:label>
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                      <label style="margin:auto;margin-top: 12%;">Webpay</label>
                      <img width="170px" height="150px" :src="webPayLogoClientes" class="payment-icon" />
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </div>
          <div v-if="status == true">
            <div class="condicion">
              <b> Para continuar tu compra y pagar con tus puntos Cencosud, porfavor ingresa el código de
                seguridad que ha llegado a tu celular
              </b>
            </div>

            <div v-if="loading" class="text-center">
              <br>
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>

            <v-col v-if="tokensocio && (loadPuntos == false) && status == true">
              <v-data-table v-if="!loadCanje" :headers="headersCenco" :items="clienteCenco" hide-default-footer
                class="elevation-1">
                <template v-slot:item.opt="{ item }">
                  <br>
                  <v-text-field style="max-width: 100px; margin: auto;margin-top: 1%;"
                    v-if="item.pointsCenco <= item.points" v-model="codigoOpt" required solo :class="labelsSup">
                  </v-text-field>
                  <v-text-field v-if="item.pointsCenco > item.points" disabled filled solo :class="labelsSup"
                    hide-details="auto">
                  </v-text-field>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn v-if="item.pointsCenco <= item.points" :disabled="!codigoOpt"
                    style="background-color: #4F74E3; color: white; margin-top: -10%;border-radius: 10px;letter-spacing: 0.66px;margin-top: 0.5%;"
                    @click="canjearpuntos()">
                    Canjear
                  </v-btn>
                  <v-btn v-if="item.pointsCenco > item.points" disabled
                    style="background-color: #4F74E3; color: white; margin-top: -10%;border-radius: 10px;letter-spacing: 0.66px;margin-top: 0.5%;"
                    @click="canjearpuntos()">
                    Canjear
                  </v-btn>
                </template>
              </v-data-table>

            </v-col>

          </div>
          <br>
        </div>

        <div v-else style="display: flex; justify-content: center; align-items: center;">
          <v-img contain :src="webPayLogo" style="max-width: 300px; height: 135px;" />
        </div>

        <div v-if="loadTokenPayment">

          <div class="text-center">

            <br>
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>

          </div>

          <br>
          <br>
          <br>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-btn disabled class="button1" id="no_3" @click="btnVolver()">Volver </v-btn>
            <br>
            <br>
            <br>

            <div v-if="typePlan == 'soap-clientes-cenco'">
              <form :action="webPayUrl" method="POST" ref="form_payment">
                <input type="hidden" name="token_ws" :value="token" />
                <v-btn disabled class="button2" key="first" type="submit" value="Pagar" id="irAPagar_3"
                  @click="obtenerToken()">
                  Ir a pagar
                </v-btn>
              </form>
            </div>
            <div v-else>
              <form :action="webPayUrl" method="POST" ref="form_payment">
                <input type="hidden" name="token_ws" :value="token" />
                <v-btn disabled class="button2" key="first" type="submit" value="Pagar" id="irAPagar_3"
                  @click="obtenerToken()">
                  Ir a pagar
                </v-btn>
              </form>
            </div>
          </div>

        </div>

        <div v-else>

          <div style="display: flex; justify-content: center; align-items: center;">
            <v-btn class="button1" id="no_3" @click="btnVolver()">Volver </v-btn>
            <br>
            <br>
            <br>

            <div v-if="typePlan == 'soap-clientes-cenco'">
              <form :action="webPayUrl" method="POST" ref="form_payment">
                <input type="hidden" name="token_ws" :value="token" />
                <v-btn class="button2" key="first" type="submit" value="Pagar" id="irAPagar_3" @click="obtenerToken()">
                  Ir a pagar
                </v-btn>
              </form>
            </div>
            <div v-else>
              <form :action="webPayUrl" method="POST" ref="form_payment">
                <input type="hidden" name="token_ws" :value="token" />
                <v-btn class="button2" key="first" type="submit" value="Pagar" id="irAPagar_3" @click="obtenerToken()">
                  Ir a pagar
                </v-btn>
              </form>
            </div>
          </div>

        </div>

        <br>
        <br>

      </div>

      <div v-if="e1 == 2 && conditions == true && screen <= 800" class="finalPayContainerMovil">

        <br>
        <br>

        <div style="display: flex; justify-content: center; align-items: center;">
          <b class="opcionPago"> Puedes pagar tu SOAP con: </b>
        </div>

        <div v-if="typePlan == 'soap-clientes-cenco' && this.patentesCarrito2.length == 1"
          style="justify-content: center; margin:auto; align-items: center; width: 100%; max-width: 400px;">
          <div style="justify-content: center; align-items: center;">
            <v-col style="margin-left: 19% !important;margin-top: 5%;margin-bottom: -14%;">
              <v-radio-group v-model="selectedPaymentMethod" @change="handlePaymentMethodChange()" row>
                <v-radio :value="'puntos'" style="margin-bottom: 20px;">
                  <template v-slot:label>
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                      <label style="margin:auto;">Puntos Cencosud</label>
                      <img width="130px" height="130px" :src="PuntosLogoClientes" class="payment-icon" />
                    </div>
                  </template>
                </v-radio>
                <v-radio :value="'tarjeta'" :disabled="paymentMethodDisabled" style="margin-bottom: 20px;">
                  <template v-slot:label>
                    <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                      <label style="margin:auto;">Webpay</label>
                      <img width="130px" height="120px" :src="webPayLogoClientes" class="payment-icon" />
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
            </v-col>
          </div>
          <div v-if="status == true">
            <p class="condicion"> Para continuar tu compra y pagar con tus puntos Cencosud, porfavor ingresa el código de
              seguridad que ha llegado a tu celular
            </p>

            <div v-if="loading" class="text-center">
              <br>
              <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
              Cargando
            </div>

            <v-col v-if="tokensocio && (loadPuntos == false) && status == true">

              <v-data-table v-if="!loadCanje" :headers="headersCenco" :items="clienteCenco" hide-default-footer
                class="elevation-1">
                <template v-slot:item.opt="{ item }">
                  <br>
                  <v-text-field style="max-width: 100px; margin: auto;margin-top: 1%;"
                    v-if="item.pointsCenco <= item.points" v-model="codigoOpt" required solo :class="labelsSup">
                  </v-text-field>
                  <v-text-field v-if="item.pointsCenco > item.points" disabled filled solo :class="labelsSup"
                    hide-details="auto">
                  </v-text-field>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn v-if="item.pointsCenco <= item.points" :disabled="!codigoOpt"
                    style="background-color: #4F74E3; color: white; margin-top: -10%;border-radius: 10px;letter-spacing: 0.66px;margin-top: 0.5%;"
                    @click="canjearpuntos()">
                    Canjear
                  </v-btn>
                  <v-btn v-if="item.pointsCenco > item.points" disabled
                    style="background-color: #4F74E3; color: white; margin-top: -10%;border-radius: 10px;letter-spacing: 0.66px;margin-top: 0.5%;"
                    @click="canjearpuntos()">
                    Canjear
                  </v-btn>
                </template>
              </v-data-table>

            </v-col>

          </div>

          <br><br>
        </div>

        <div v-else style="display: flex; justify-content: center; align-items: center;">
          <v-img contain :src="webPayLogo" style="max-width: 300px; height: 135px;" />
        </div>

        <div v-if="loadTokenPayment">

          <div class="text-center">

            <br>
            <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>

          </div>

          <br>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <v-btn class="button1Mobile" id="no_3" style=" margin-right: 30px" @click="btnVolver()">Volver </v-btn>

            <br>

            <div v-if="typePlan == 'soap-clientes-cenco'">
              <form :action="webPayUrl" method="POST" ref="form_payment">
                <input type="hidden" name="token_ws" :value="token" />
                <v-btn class="button2Mobile" key="first" type="submit" value="Pagar" id="irAPagar_3"
                  @click="obtenerToken()">
                  Ir a pagar
                </v-btn>
              </form>
            </div>

            <div v-else>
              <form :action="webPayUrl" method="POST" ref="form_payment">
                <input type="hidden" name="token_ws" :value="token" />
                <v-btn class="button2Mobile" key="first" type="submit" value="Pagar" id="irAPagar_3"
                  @click="obtenerToken()">
                  Ir a pagar
                </v-btn>
              </form>
            </div>

          </div>

        </div>

        <div v-else>

          <div style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
            <v-btn class="button1Mobile" id="no_3" style=" margin-right: 30px" @click="btnVolver()">Volver </v-btn>

            <br>

            <div v-if="typePlan == 'soap-clientes-cenco'">
              <form :action="webPayUrl" method="POST" ref="form_payment">
                <input type="hidden" name="token_ws" :value="token" />
                <v-btn class="button2Mobile" key="first" type="submit" value="Pagar" id="irAPagar_3"
                  @click="obtenerToken()">
                  Ir a pagar
                </v-btn>
              </form>
            </div>

            <div v-else>
              <form :action="webPayUrl" method="POST" ref="form_payment">
                <input type="hidden" name="token_ws" :value="token" />
                <v-btn class="button2Mobile" key="first" type="submit" value="Pagar" id="irAPagar_3"
                  @click="obtenerToken()">
                  Ir a pagar
                </v-btn>
              </form>
            </div>

          </div>

        </div>

        <br>
        <br>

      </div>

    </v-container>

  </div>
</template>

<script>
import RutUtils from "../utils/rut.utils";
import Cuotas from "@/assets/images/Cuotas.png";
import InitForm from "@/components/InitForm.vue";
import SecForm from "@/components/SecForm.vue";
import ThirdForm from "@/components/ThirdForm.vue";
import ShieldService from "../services/shield.service";
import CarritoCompra from "@/components/Carrito.vue";
import SoapService from "../services/soap.service";
import webPayLogo from "@/assets/images/webpay.png";
import webPayLogoClientes from "@/assets/images/Tarjetas_Cencosud_resize.png";
import PuntosLogoClientes from "@/assets/images/logocenco.png";
import cencoLogin from "@/assets/images/CencoImage.jpg";
import BodumService from '../services/bodum.service';
import swal from 'sweetalert';
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {
  name: "Form",
  components: {
    InitForm,
    SecForm,
    ThirdForm,
    CarritoCompra
  },
  data() {
    return {
      openDialog: false,
      paymentMethodDisabled: false,
      externalId: null,
      codigoOpt: null,
      editedIndex: -1,
      currentPage: 1,
      itemsPerPage: 5,
      status: null,
      switchFormaPago: null,
      cencoLogin,
      dialog: false,
      newRut2: "",
      contrasenaCliente: "",
      loadLogin: false,
      respuestaLogin: null,
      valid: false,
      clienteCenco: [],
      selectedPaymentMethod: '',
      paySelect: true,
      selecPPay: false,
      typePlan: null,
      conditions: null,
      webPayLogo,
      webPayLogoClientes,
      PuntosLogoClientes,
      token: null,
      bannerView: null,
      cartBoxView: null,
      btnFindSoapView: null,
      btnCartButtonView: null,
      stepperHeaderView: null,
      stepLabelSelected: null,
      btnFindSoapView3: null,
      Cuotas,
      switch: true,
      generaropt: null,
      loadSoap: true,
      keygen: null,
      e1: 1,
      steps: null,
      newCar: false,
      screen: window.screen.width,
      loading: false,
      loadingFinal: false,
      patenteData: {},
      patentes: [],
      patenteUtils: null,
      patentesCarrito: [],
      patentesCarrito2: [],
      priceTotal: 0,
      typeCliente: null,
      querySuccessState: null,
      carData: {
        id: null,
        patente: null,
        patenteDv: null,
        year: null,
        type: null,
        marca: null,
        model: null,
        motor: null,
        price: null,
        externalProductId: null,
      },
      propietarioData: {
        rut: null,
        nombres: null,
        firtlastname: null,
        secondlastname: null,
        email: null,
        phoneNumber: null,
        propietario: true,
        rutPropietario: null,
        remarque: null,
        pricePuntos: null,
        campana: null,
      },
      planAuto: null,
      planCamioneta: null,
      planMoto: null,
      planArrastre: null,
      planBus: null,
      plans: {},
      planId: null,
      loadTokenPayment: false,
      loadPlans: true,
      webPayUrl: process.env.VUE_APP_WEBPAY_URL,
      editedItem: {
        patenteNumber: '',
        typeVehicle: '',
        modelVehicle: '',
        personEmail: '',
        price: 0,
      },
      defaultItem: {
        patenteNumber: '',
        typeVehicle: '',
        modelVehicle: '',
        personEmail: '',
        price: 0,
      },
      headersCenco: [
        { text: 'Nombre Cliente', align: 'start', sortable: false, value: 'name', align: 'center', },
        { text: 'Apellido Cliente', value: 'surname', align: 'center', sortable: false, },
        { text: 'Puntos Cliente', value: 'points', align: 'center', sortable: false, },
        { text: 'Puntos Plan a Contratar', value: 'pointsCenco', align: 'center', sortable: false, },
        { text: 'Código de verificación', value: 'opt', sortable: false, align: 'center', sortable: false, },
        { text: 'Realizar canje', value: 'actions', sortable: false, align: 'center', },
      ],
      confirmRules: [(v) => !!v || "Selecciona una opción"],
      rutRules2: [
        (v) => !!v || "Rut del cliente es requerido",
        (v) => RutUtils.validateRut(v) || "Rut no válido",
      ],
      contrasenaRules: [
        (v) => !!v || "La contraseña es requerida",
      ],
      conditionsRules: [
        (v) => !!v || "",
      ]
    };
  },
  async mounted() {
    this.carData.patente = localStorage.getItem('patente');
    this.propietarioData.email = localStorage.getItem('email');
    this.typePlan = localStorage.getItem('typePlan');
    const query = this.$route.query;
    if (query.process == "behind") {
      window.location.pathname = '/info'
    }
    if (this.typePlan) {
      var shieldRes = await ShieldService.getPlans(this.typePlan)
      if (shieldRes.status == 'ok') {
        this.plans = shieldRes.data[0]
        localStorage.setItem('planId', this.plans._id)
        this.loadPlans = false
      }
    }
    this.propietarioData.campana = process.env.VUE_APP_DOMINIO + window.location.pathname
    if (this.screen > 800) {
      this.steps = 'steps'
      this.bannerView = 'bannerView'
      this.cartBoxView = 'cartBoxView'
      this.btnFindSoapView = 'btnFindSoapView'
      this.btnCartButtonView = 'btnCartButtonView'
      this.stepperHeaderView = 'stepperHeaderView'
      this.stepLabelSelected = 'stepLabelSelected'
      this.btnFindSoapView3 = 'btnFindSoapView3'
    } else if (this.screen <= 800) {
      this.steps = 'steps2'
      this.bannerView = 'bannerView2'
      this.cartBoxView = 'cartBoxView2'
      this.btnFindSoapView = 'btnFindSoapView2'
      this.btnCartButtonView = 'btnCartButtonView2'
      this.stepperHeaderView = 'stepperHeaderView2'
      this.stepLabelSelected = 'stepLabelSelected2'
      this.btnFindSoapView3 = 'btnFindSoapView3Mobile'
    }
    if (query) {
      if (query.success) {
        this.querySuccessState = 'ok'
        this.e1 = 3
      } else if (query.keygen) {
        this.keygen = localStorage.getItem('keygen');
        this.e1 = 2
      } else if (query.newCar == 'true') {
        this.newCar = true
        this.keygen = localStorage.getItem('keygen');
        await ShieldService.getCars(this.keygen).then((response) => {
          if (response.status == 'ok') {
            response.data.forEach(car => {
              this.patentesCarrito.push(car)
            });
          }
        })
      }

      if (this.carData.patente && this.propietarioData.email && this.typePlan && this.e1 == 1) {


        switch (this.typePlan) {
          case 'soap-clientes-cenco':
            this.typeCliente = 'clientes'
            break;
          case 'soap-general-cenco':
            this.typeCliente = 'general'
            break;
          case 'soap-colaborador-cenco':
            this.typeCliente = 'colaborador'
            break;
          case 'soap-promocional-cenco':
            this.typeCliente = 'promocional'
            break;

          default:
            break;
        }

        // reCaptcha v3 token
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('consultSoap')

        var soapRes = await SoapService.consultaSoap(this.carData.patente, token)

        if (soapRes.data == null) {
          swal({
            title: "¡Lo sentimos!",
            text: "No existen registros correspondientes a la patente ingresada",
            icon: "info",
            button: "Ok",
          }).then(() => {
            if (query.newCar == 'true') {
              window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio?newCar=true'
            } else {
              window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
            }
          });
        } else if (soapRes.status == 'ok') {
          this.carData.id = soapRes.data._id
          this.carData.year = soapRes.data.anio_fabricacion
          this.carData.patenteDv = soapRes.data.dv_ppu
          this.carData.type = soapRes.data.tipo_vehiculo
          this.carData.marca = soapRes.data.marca
          this.carData.model = soapRes.data.modelo
          this.carData.motor = soapRes.data.numero_motor

          this.propietarioData.rut = soapRes.data.rut + soapRes.data.dv

          var nombreCompleto = soapRes.data.nombre_razonsocial.split(' ')

          this.propietarioData.nombres = nombreCompleto[3] ? nombreCompleto[2] + ' ' + nombreCompleto[3] : nombreCompleto[2]
          this.propietarioData.firtlastname = nombreCompleto[0]
          this.propietarioData.secondlastname = nombreCompleto[1]
          this.loadSoap = false
        }

        switch (this.carData.type) {
        case 'AUTOMOVIL':
        case 'JEEP':
        case 'STATION WAGON':
        case 'LIMUSINA':
          this.carData.price = this.plans.soap.car.price;
          this.propietarioData.remarque = this.plans.soap.car.remarque;
          this.carData.externalProductId = this.plans.soap.car.externalProductId;
          break;
          
        case 'CAMIONETA':
        case 'FURGON':
          this.carData.price = this.plans.soap.camioneta.price;
          this.propietarioData.remarque = this.plans.soap.camioneta.remarque;
          this.carData.externalProductId = this.plans.soap.camioneta.externalProductId;
          break;

        case 'MOTO':
        case 'CUATRIMOTO':
        case 'MOTONETA':
          this.carData.price = this.plans.soap.moto.price;
          this.propietarioData.remarque = this.plans.soap.moto.remarque;
          this.carData.externalProductId = this.plans.soap.moto.externalProductId;
          break;

        case 'CARRO DE ARRASTRE':
        case 'CASA RODANTE MOTORIZADA':
          this.carData.price = this.plans.soap.arrastre.price;
          this.propietarioData.remarque = this.plans.soap.arrastre.remarque;
          this.carData.externalProductId = this.plans.soap.arrastre.externalProductId;
          break;

        case 'MINIBUS':
          this.carData.price = this.plans.soap.bus.price;
          this.propietarioData.remarque = this.plans.soap.bus.remarque;
          this.carData.externalProductId = this.plans.soap.bus.externalProductId;
          break;

        default:
            swal({
              title: "Tipo de vehículo no válido",
              icon: "info",
              button: "Ok",
            }).then(() => {
              if (query.newCar == 'true') {
                window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio?newCar=true'
              } else {
                window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
              }
            });
            break;
        }

        try {

          // reCaptcha v3 token
          await this.$recaptchaLoaded()
          const tokenConsulta = await this.$recaptcha('consultaSoap');

          var consultaCarSoap = await ShieldService.createSoapConsulta(this.propietarioData, this.carData, tokenConsulta)
          console.log(consultaCarSoap)
        } catch (error) {
          console.log(error)
        }

      }

      if (this.carData.patente && this.propietarioData.email && this.typePlan && this.e1 == 2) {
        this.keygen = localStorage.getItem('keygen');
        await ShieldService.getCars(this.keygen).then((response) => {
          if (response.status == 'ok') {
            response.data.forEach(car => {
              let externalProductId = ''; // Variable para almacenar el externalProductId
              switch (car.typeVehicle) {
                case 'AUTOMOVIL':
                case 'JEEP':
                case 'STATION WAGON':
                case 'LIMUSINA':
                  externalProductId = process.env.VUE_APP_AUTOMOVIL_JEEP_WAGON_ROW_ID;
                  break;
                case 'CAMIONETA':
                case 'FURGON':
                  externalProductId = process.env.VUE_APP_CAMIONETA_FURGON_ROW_ID;
                  break;
                case 'MOTO':
                case 'CUATRIMOTO':
                case 'MOTONETA':
                  externalProductId = process.env.VUE_APP_CAMIONETA_MOTO_ID;
                  break;
                case 'CARRO DE ARRASTRE':
                case 'CASA RODANTE MOTORIZADA':
                  externalProductId = process.env.VUE_APP_RODANTE_CHASIS_ID;
                  break;
                case 'MINIBUS':
                  externalProductId = process.env.VUE_APP_MINIBUS_ID;
                  break;
                default:
                  externalProductId = 'No definido';
              }
              // Añadir el campo externalProductId al objeto car
              car.externalProductId = externalProductId;
              // Agregar el objeto car al arreglo patentesCarrito2
              this.patentesCarrito2.push(car);
            });
          }
        }).catch(error => {
          console.error('Error al obtener los datos de los carros:', error);
        });
      }
    }
  },
  methods: {
    changeRut2(rut) {
      this.rutCliente = RutUtils.cleanRut(rut);
      this.newRut2 = RutUtils.formatRut(rut);
    },
    async logincenco() {
      this.clienteCenco = []
      this.CencoSelect = false
      this.webPaySelect = true
      if (this.$refs.form.validate()) {
        this.loadLogin = true
        this.tokenpartner = await BodumService.getTokenPartner();
        // console.log("token partner: ", this.tokenpartner)
        this.dialog = true;
        this.respuestaLogin = await BodumService.loginsocio(this.tokenpartner, this.rutCliente, this.contrasenaCliente);
        // console.log("token socio: ", this.respuestaLogin)
        // console.log("usuario: ", this.rutCliente)
        // console.log("contraseña: ", this.contrasenaCliente)
        if (this.respuestaLogin.code == 'USER_TMP_PWD' || this.respuestaLogin.code == 'OK') {
          this.paymentMethodDisabled = true;
          this.openDialog = true;
          this.loadPuntos = true
          this.tokensocio = this.respuestaLogin.token;
          this.puntosclientes = await BodumService.consultaPuntos(this.tokenpartner, this.rutCliente);
          // console.log(this.puntosclientes);
          this.loadPuntos = false;
          // console.log(this.switchFormaPago);
          // console.log(this.tokensocio);
          if (this.switchFormaPago == false && this.tokensocio != null) {
            // console.log(this.patentesCarrito2[0]);
            var datos = {
              name: this.puntosclientes.nombre,
              surname: this.puntosclientes.apellidoPaterno,
              points: this.puntosclientes.puntos,
              pointsCenco: this.patentesCarrito2[0].pricePuntos,
            }
            this.clienteCenco.push(datos)
            // console.log(this.clienteCenco);
            this.generaropt = await BodumService.GenerarOPT(this.tokenpartner, this.tokensocio);
            // console.log(this.generaropt)
            if (this.generaropt.status == "ok") {
              this.dialogOpt = true
            }
          }
          this.loadLogin = false
          swal({
            title: "Login Exitoso",
            text: "Ahora puede continuar con la compra!",
            icon: "success",
            button: "Ok",
          });
          this.status = true
          this.dialog = false;

          if (datos.points < datos.pointsCenco) {
            this.paymentMethodDisabled = false;
            swal({
              title: "Ups! Faltan puntos para contratar el seguro.",
              text: "Porfavor, cambiar método de pago!",
              icon: "error",
              button: "Ok",
            });
          }

        } else {
          this.loadLogin = false
          swal({
            title: "Credenciales inválidas",
            text: "",
            icon: "error",
            button: "Ok",
          });
        }
      }

    },

    async canjearpuntos() {
      this.loadCanje = true
      this.loading = true
      this.externalId = this.patentesCarrito2[0].patenteNumber + '2024' + '-01'
      // console.log(this.externalId);
      this.validarcanje = await BodumService.PagoPuntos(this.tokenpartner, this.tokensocio, this.codigoOpt, this.patentesCarrito2[0].pricePuntos, this.patentesCarrito2[0].externalProductId, this.keygen, this.externalId);
      // console.log(this.validarcanje);
      if (this.$refs.form.validate()) {
        if (this.validarcanje.status == 'ok') {

          const query = this.$route.query;
          this.loadCanje = false
          var res = await ShieldService.pagoCencosud(this.keygen, this.typePlan)
          if (res.includes('success=true')) {
            this.loading = false
            window.location.href = res;
            swal({
              title: "Canje Exitoso",
              text: "La compra del seguro se ha completado",
              icon: "success",
              button: "Ok",
            });
          } else {
            this.loading = false
            swal({
              title: "Error al procesar el SOAP",
              text: "",
              icon: "error",
              button: "Ok",
            });
          }
        } else {
          this.paymentMethodDisabled = false;
          this.loading = false
          this.openDialog = false
          this.loadCanje = false
          swal({
            title: "Canje inválido",
            text: "",
            icon: "error",
            button: "Ok",
          });
        }
      }
    },
    handlePaymentMethodChange() {
      if (this.selectedPaymentMethod === 'tarjeta') {
        this.selectedPaymentMethod = 'tarjeta';
        // console.log('Web Pay seleccionado');
      } else if (this.selectedPaymentMethod === 'puntos') {
        this.selectedPaymentMethod = 'puntos';
        this.switchFormaPago = false
        // console.log('Puntos Cencosud seleccionado');
      }
    },
    async obtenerToken() {

      event.preventDefault();
      this.planId = localStorage.getItem('planId');
      this.loadTokenPayment = true;

      if (this.selectedPaymentMethod === 'tarjeta' || this.selectedPaymentMethod != 'puntos') {
        var webPayToken = await ShieldService.create(this.keygen, this.planId);
        if (webPayToken.status == 'ok') {
          this.token = webPayToken.data.token;
          setTimeout(() => {
            this.submitPago();
          }, 3000);
        } else {
          this.loadTokenPayment = false;
          swal({
            title: "UPS! Algo salió mal.",
            text: "Vuelva a intentarlo nuevamente.",
            icon: "info",
            button: "Ok",
          }).then(() => {
            window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
          });
        }
      } else if (this.selectedPaymentMethod === 'puntos') {
        if (this.openDialog == false) {
          this.dialog = 'true';
          this.loadTokenPayment = false;
        }
      }
    },
    async submitPago() {
      // console.log(this.selectedPaymentMethod);
      const irAPagar = document.getElementById('irAPagar_3');

      if (this.conditions && this.propietarioData.rutPropietario) {
        // console.log('entra2?');
        if ((this.selectedPaymentMethod === 'tarjeta' || this.selectedPaymentMethod != 'puntos') || this.patentesCarrito2.length != 0) {
          this.$refs.form_payment.submit();
          return;
        } else if (this.selectedPaymentMethod === 'puntos') {
          if (this.openDialog == false) {
            this.dialog = 'true';
            this.loadTokenPayment = false;
          }
        }
      } else {
        this.loadTokenPayment = false;
        swal({
          icon: 'info',
          title: '¡Precaución!',
          text: 'Debes autorizar el tratamiento de tus datos personales e ingresar rut de quién realizará el pago.',
          timer: 3000,
          buttons: false,
        });
      }
    },
    async redirectFindSoap() {
      const consulta_1 = document.getElementById('consulta_1');
      const consulta_5 = document.getElementById('consulta_5');
      window.location.href = process.env.VUE_APP_DOMINIO + 'consulta'
      // this.$router.push({ path: "/consulta" });
    },
    btnVolver() {
      window.location.href = process.env.VUE_APP_DOMINIO + 'clientes/datos'
      // if (this.newCar == true) {
      //     window.location.href = process.env.VUE_APP_DOMINIO + 'general/datos' + '?patente=' + this.carData.patente + '&email=' + this.propietarioData.email + '&newCar=' + this.newCar + '&type=soap-general-cenco'
      //   } else {
      //     window.location.href = process.env.VUE_APP_DOMINIO + 'general/datos' + '?patente=' + this.carData.patente + '&email=' + this.propietarioData.email + '&type=soap-general-cenco'
      //   }
    },
    getToken(elementos) {
      // this.token = elementos.token;
      this.propietarioData.rutPropietario = elementos.rut;
      this.conditions = elementos.conditions;
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-stepper .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error) .v-stepper__step__step {
  background: #91A6E4;
  border-radius: 50%;
  overflow: hidden;
  font-size: 18px;
  font-weight: bold;
  /* Evitar que el contenido se visualice fuera del borde*/
}

.theme--light.v-stepper .v-stepper__label {
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 15px;
  letter-spacing: 0.23px;
  color: #1269B0;
  opacity: 1;
  font-size: 15px;
  width: 208px;
}

.v-input .v-label {
  text-align: left;
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: 0.18px;
  color: #1269B0;
  opacity: 1;
}

span.v-stepper__step__step.primary {
  width: 24px;
  height: 24px;
  opacity: 1;
  background: #4F74E3 !important;
  font-size: 18px;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}

.steps {
  width: 100%;
  margin-top: 5%;
}

.steps2 {
  min-width: 100%;
}

.containerStyle {
  background-color: #FFF;
  height: 80%;
  margin-bottom: 6.5%;
}

.containerStyle2 {
  background-color: #FFF;
  height: 80%;
  margin-bottom: 6%;
}

.bannerView {
  font-weight: bold;
  text-align: center;
  margin-top: -12%;
  font-size: 46px;
  color: #1269B0;
  font-family: 'Nunito Sans', sans-serif;
}

.bannerView2 {
  font-weight: bold;
  text-align: center;
  margin-top: -42%;
  font-size: 28px;
  color: #1269B0;
  margin-bottom: 9%;
  font-family: 'Nunito Sans', sans-serif;
}

.stepLabelSelected {
  font-size: 16px;
  font-weight: normal;
  font-family: 'Nunito Sans', sans-serif;
}

.stepLabelSelected2 {
  font-size: 12px;
  font-weight: normal;
  width: 80%;
  font-family: 'Nunito Sans', sans-serif;
}

.stepLabel {
  font-size: 20px;
  font-family: 'Nunito Sans', sans-serif;
}

.btnFindSoapView {
  width: 180px;
  height: 36px !important;
  background: #EF7C00 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  margin-left: 42%;
  // margin-top: 2%;
  margin-bottom: -10.99%;
}

.btnFindSoapView2 {
  width: 200px;
  height: 36px !important;
  background: #EF7C00 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  // margin-left: -105%;
  // margin-bottom: -49%;
}


.btnFindSoapView3 {
  width: 180px;
  height: 36px !important;
  background: #EF7C00 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  font: normal normal normal 22px/27px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  margin-left: 70%;
  margin-top: 5%;
  // margin-bottom: -10.99%;
}

.btnFindSoapView3Mobile {
  width: 200px;
  height: 36px !important;
  background: #EF7C00 0% 0% no-repeat padding-box !important;
  border-radius: 5px;
  text-align: center;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  // margin-left: -107%;
  // margin-top: 20%;
  font-size: 14px !important;
  // margin-bottom: -22%;
}

.cart {
  margin-left: 58%;
  margin-bottom: 1%;
}

.cartBoxView {
  margin-top: 9%;
  margin-left: 40%;
  margin-bottom: -4.6%;
  // background-color: greenyellow;
}

.cartBoxView2 {
  margin-left: 11%;
  margin-top: 32%;
  display: flex;
  width: 80%;
}

.finalPayContainer {
  background-color: #F3F3F3;
  width: 116.5%;
  margin-left: -8%;
  margin-right: auto;
  margin-bottom: -9%;
}

.btnCartButtonView2 {
  margin-left: 12%;
  margin-top: -3.9%;
}

.finalPayContainerMovil {
  background-color: #F3F3F3;
  width: 100%;
  // margin-left: -8%;
  margin-right: auto;
  margin-bottom: -10%;
}

.finalPayContainerDesktop {
  background-color: #F3F3F3;
  width: 140%;
  margin-left: -19%;
  margin-bottom: -107px;
}

.stepperHeaderView {
  box-shadow: none;
  margin: auto;
  width: 75%;
  margin-bottom: -2%;
}

.stepperHeaderView2 {
  box-shadow: none;
  margin: auto;
  width: 100%;
  margin-bottom: -8%;
}

.opcionPago {
  color: var(--AD-Primario-1, #1269B0);
  font-family: 'Nunito Sans', sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.24px;
  text-transform: uppercase;
}

.button1 {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: -7%;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}

.button1Mobile {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 5%;
  font-weight: bold;
  margin-bottom: 2%;
  font-family: 'Nunito Sans', sans-serif;
}

.button2 {
  width: 270px;
  height: 50px !important;
  background-color: #4F74E3 !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 35%;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}

.button2Mobile {
  width: 270px;
  height: 50px !important;
  background-color: #4F74E3 !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font-size: 18px !important;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: -2%;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.titulo {
  color: #1269B0;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0px;
  font-family: 'Nunito Sans', sans-serif;
  text-align: center;
  line-height: 1.2;
}

.login {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 0.3rem;
  background-color: #4F74E3 !important;
  height: 40px !important;
  line-height: 40px;
  width: 100%;
  padding: 0px 1.5rem !important;
  cursor: pointer;
  color: white !important;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  margin: auto;
}

.condicion {
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  color: #1269B0;
  text-transform: uppercase;
  opacity: 1;
}

.v-stepper:not(.v-stepper--vertical) .v-stepper__label {
  display: contents !important;
}
</style>