<template>
  <v-container
    style="position: relative; width: 100%; justify-content: center; height: 100%; background: #FFFFFF 0% 0% no-repeat padding-box; opacity: 1;">

    <div class="text-center">

      <v-row justify="center"><v-dialog v-model="dialog" max-width="800px" min-height="800px">
          <v-card>
            <v-card-text>
              <div v-if="!loadLogin" class="form-container">
                <v-col>
                  <br>
                  <br>
                  <h1 class="titulo text-center"> Inicia tu sesión</h1><br>
                  <h2 class="text-center"> Ingresa tu rut y contraseña de Puntos Cencosud</h2><br>
                  <br><label>Rut</label>
                  <v-form ref="form" v-model="valid">
                    <v-text-field v-model="newRut2" :rules="rutRules2" label="Ingresa tu rut" required filled solo
                      hide-details="auto" @input="changeRut2">
                    </v-text-field><br><label>Contraseña</label>
                    <v-text-field v-model="contrasenaCliente" :rules="contrasenaRules" label="Ingresa tu contraseña"
                      required filled solo hide-details="auto" type="password">
                    </v-text-field>
                  </v-form>
                  <br><v-btn class="login" @click="logincenco()"> Iniciar sesión </v-btn><br>
                  <br><a
                    style="color: rgb(108, 117, 125); text-decoration: none; background-color: transparent;  justify-content: center;font-family: 'Nunito Sans', sans-serif;"
                    href="https://www.puntoscencosud.cl/puntos/recuperar">Quiero recuperar mi contraseña de Puntos
                    Cencosud</a>
                </v-col>
                <v-col v-if="screen > 800">
                  <v-img contain :src="cencoLogin" />
                </v-col>
              </div>
              <div v-if="loadLogin" class="text-center">
                <br>
                <br>
                <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
                Cargando
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>

      <br>
      <div class="rectangulo">
        <div class="valor">
          <b> Valor total a pagar</b>
        </div>
        <br>

        <div :class="priceBox">
          <hr class="hrStyle">
          <b class="precio">{{ formatPrice(priceTotal) }}</b>
          <hr class="hrStyle">
        </div>
        <br>

      </div>

      <div v-if="(typeCliente == 'clientes') && (patentes.length == 1)">

        <br>

      </div>

      <br>
      <br>

      <template>
        <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
          :items-per-page="isMobile ? 1 : 5" class="elevation-1 table" no-data-text="CARRITO VACÍO ¡AÑADE TU SOAP!">
          <template v-slot:top>
            <!-- INICIO DIALOG WEB -->
            <v-dialog v-if="screen > 800" v-model="dialogDelete" max-width="600px">
              <v-card>

                <v-card-title class="text-h5">
                  <div class="titleBox">
                    <b class="title1">¿Estás seguro de </b>
                    <b class="title2">eliminar</b>
                    <b class="title1">este producto?</b>
                  </div>
                </v-card-title>

                <v-card-actions>

                  <v-spacer></v-spacer>
                  <v-btn class="btnClosePopUp" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn class="btnConfirmPopUp" id="quitarOtro_3" text @click="deleteItemConfirm">Confirmar</v-btn>
                  <div class="space"></div>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- FIN DIALOG WEB -->


            <!-- INICIO DIALOG MOVIL -->
            <v-dialog v-else v-model="dialogDelete" max-width="360px">
              <v-card>

                <v-card-title class="text-h5">
                  <div class="titleBoxMobile">
                    <b class="title1">¿Estás seguro de <br><span style="color:#1269B0">eliminar</span> este producto?</b>
                  </div>
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div class="btnBoxMobile">
                    <v-btn class="btnClosePopUp2" text @click="closeDelete">Cancelar</v-btn>
                    <br><br>
                    <v-btn class="btnConfirmPopUp2" id="quitarOtro_3" text @click="deleteItemConfirm">Confirmar</v-btn>
                    <div class="space"></div>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- FIN DIALOG MOVIL -->

          </template>


          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2 trash" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.price="{ item }">
            <label style="margin:auto">{{ formatPrice(item.price) }}</label>
          </template>



        </v-data-table>

      </template>

      <br>
      <br>

      <div v-if="pagar != true" class="finalBox">
        <b class="question">¿QUIERES AGREGAR OTRO VEHÍCULO?</b>
        <br v-if="screen <= 800">
        <br v-if="screen <= 800">
        <v-btn class="button1" id="agregarOtro_3" @click="NewCarro()">Agregar otro </v-btn>
        <br v-if="screen <= 800">
        <br v-if="screen <= 800">
        <v-btn v-if="screen > 800" class="button2" @click="obtenerToken()" id="confirmar_3">Continuar</v-btn>

        <v-btn v-if="screen <= 800" class="button2" style="margin-top: 5%" @click="obtenerToken()"
          id="confirmar_3">Continuar Pago</v-btn>
      </div>

      <v-form ref="form" v-model="valid">

        <v-col v-if="pagar != false" style="max-width: 320px; margin:auto;">
          <b class="title"> INGRESE RUT PAGADOR</b>
          <br>
          <br>
          <label class="labelName"><b>Ingrese el RUT</b></label>
          <v-text-field v-model="newRut" :rules="rutRules" label="Ingrese RUT de quien paga" required solo
            :class="patentFieldView" hide-details="auto" @change="changeRut">
          </v-text-field>
        </v-col>
        <br>
        <v-col v-if="pagar != false">
          <div class="checkbox">
            <v-checkbox style="margin-top:2.5%" v-model="conditions" :rules="conditionsRules" required dense
              @change="sendParams()"></v-checkbox>
            <label>
              <b>AUTORIZACIÓN PARA TRATAMIENTO DE DATOS PERSONALES:</b> <br> Autorizo que mi información personal sea
              recopilada, almacenada, tratada y divulgada por Cencosud Scotiabank, según lo dispuesto en la Ley Nº19.628 y
              conforme a su política de privacidad contenido en www.seguroscencosud.cl y www.tarjetacencosud.cl/
            </label>
          </div>
          <br v-if="screen >= 800">
        </v-col>
      </v-form>


      <div v-if="loadCanje" class="text-center">
        <br>
        <br>
        <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
        Cargando
      </div>

    </div>

  </v-container>
</template>

<script>
import RutUtils from "../utils/rut.utils";
import webPayLogo from "@/assets/images/webpay.png";
import webPayLogo2 from "@/assets/images/WebpayPlus.png";
import cencoWebPayLogo from "@/assets/images/transbank-cenco.png";
import cencoLogo from "@/assets/images/logocenco.png";
import ShieldService from "../services/shield.service";
import BodumService from '../services/bodum.service';
import cencoLogin from "@/assets/images/CencoImage.jpg";
import swal from 'sweetalert';
import Vue from "vue";
import { VueReCaptcha } from 'vue-recaptcha-v3'
const { VUE_APP_RECAPTCHA3_SITE_KEY } = process.env;

Vue.use(VueReCaptcha, { siteKey: VUE_APP_RECAPTCHA3_SITE_KEY })

export default {

  name: "PlanForm",
  props: [],
  data: () => ({
    typePlan: null,
    isMobile: false,
    status: false,
    screen: window.screen.width,
    typeCliente: null,
    keygen: null,
    patentes: [],
    priceTotal: 0,
    priceBox: null,
    propietarioData: {
      rutPropietario: null,
      propietario: null,
    },
    patentFieldView: null,
    externalId: null,
    planId: null,
    pagoSelect: false,
    type: null,
    token: null,
    pagar: false,
    conditions: null,
    loadPuntos: true,
    loadLogin: false,
    loadCanje: false,
    dialog: false,
    dialogOpt: false,
    newRut2: "",
    validarcanje: null,
    valid: false,
    rutCliente: null,
    contrasenaCliente: null,
    cencoLogin,
    codigoOpt: null,
    respuestaLogin: null,
    puntosclientes: null,
    tokenpartner: null,
    inputOptEsVisible: false,
    switchFormaPago: null,
    tokensocio: null,
    generaropt: null,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    webPayLogo,
    webPayLogo2,
    cencoWebPayLogo,
    cencoLogo,
    webPayUrl: process.env.VUE_APP_WEBPAY_URL,
    newRut: "",
    dialogDelete: false,
    dialog: false,
    editedIndex: -1,
    currentPage: 1,
    itemsPerPage: 5,
    editedItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    defaultItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    headers: [
      {
        text: 'Patente',
        align: 'start',
        sortable: false,
        value: 'patenteNumber',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'typeVehicle', align: 'center', sortable: false, },
      { text: 'Modelo', value: 'modelVehicle', align: 'center', sortable: false, },
      { text: 'Correo', value: 'personEmail', align: 'center', sortable: false, },
      { text: 'Valor SOAP', value: 'price', align: 'center', sortable: false, },
      { text: 'Eliminar', value: 'actions', sortable: false, align: 'center', },
    ],
    headersCenco: [
      {
        text: 'Nombre Cliente',
        align: 'start',
        sortable: false,
        value: 'name',
        align: 'center',
      },
      { text: 'Apellido Cliente', value: 'surname', align: 'center', },
      { text: 'Puntos Cliente', value: 'points', align: 'center', },
      { text: 'Puntos Plan a Contratar', value: 'pointsCenco', align: 'center', },
      { text: 'Código de verificación', value: 'opt', sortable: false, align: 'center', },
      { text: 'Realizar canje', value: 'actions', sortable: false, align: 'center', },
    ],
    clienteCenco: [],
    confirmRules: [(v) => !!v || "Selecciona una opción"],
    rutRules: [
      (v) => !!v || "Rut es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    rutRules2: [
      (v) => !!v || "Rut del cliente es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    contrasenaRules: [
      (v) => !!v || "La contraseña es requerida",
    ],
    conditionsRules: [
      (v) => !!v || "",
    ]
  }),
  async mounted() {
    this.propietarioData.propietario = 'true'
    this.pagar = false
    this.typePlan = localStorage.getItem('typePlan');
    const query = this.$route.query;

    if (query.external) {
      this.externalId = query.external
    }

    if (this.screen > 800) {
      this.patentFieldView = 'patentFieldView'
      this.priceBox = 'priceBox'
    } else if (this.screen <= 800) {
      this.patentFieldView = 'patentFieldView2'
      this.priceBox = 'priceBox2'
    }


    if (this.typePlan && query.keygen) {
      this.keygen = query.keygen
      switch (this.typePlan) {
        case 'soap-clientes-cenco':
          this.typeCliente = 'clientes'
          break;
        case 'soap-general-cenco':
          this.typeCliente = 'general'
          break;
        case 'soap-colaborador-cenco':
          this.typeCliente = 'colaborador'
          break;
        case 'soap-promocional-cenco':
          this.typeCliente = 'promocional'
          break;

        default:
          break;
      }
    }

    let excluyentes = [];
    // console.log(this.keygen);
      if (this.keygen && typeof this.keygen === 'string' && this.keygen.trim() !== '') {
      // console.log(this.keygen);
      this.patenteData = await ShieldService.getCars(this.keygen).then((response) => {
        if (response.status == 'ok') {
          response.data.forEach(car => {
            excluyentes.push(car.patenteNumber);
            this.patentes.push(car)
            this.priceTotal = this.priceTotal + car.price
          });

          const hayRepetidos = excluyentes.length !== new Set(excluyentes).size;

          if (hayRepetidos) {
            swal({
              title: "Existen patentes repetidas",
              text: "Será redirigido al inicio de la plataforma",
              icon: "error",
              button: "Ok",
            }).then(() => {
              window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
            });
          }

        } else if (response.status == 'failed') {
          swal({
            title: "No posee SOAP's en su carrito",
            text: "Será redirigido al inicio de la plataforma",
            icon: "info",
            button: "Ok",
          }).then(() => {
            window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
          });
        }
      });
    } else {
      // console.log('this.keygen no existe o es inválido');
    }
  },

  methods: {
    deleteItem(item) {
      this.editedIndex = this.patentes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      // reCaptcha v3 token
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('deleteSoap')

      await ShieldService.deleteCar(this.patentes[this.editedIndex]._id, token).then((response) => {
        if (response == 'Delete car') {
          this.patentes.splice(this.editedIndex, 1)
          this.closeDelete()
        }
      })
      location.reload();
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    changeRut2(rut) {
      this.rutCliente = RutUtils.cleanRut(rut);
      this.newRut2 = RutUtils.formatRut(rut);
    },
    back() {
      this.$emit("back");
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    NewCarro() {
      // console.log(this.patentes.length);
      if (this.patentes.length >= 20) {
        swal({
            title: "¡Límite alcanzado!",
            text: "No puedes agregar más elementos al carrito.",
            icon: "info",
            button: "Ok",
        });
        return; // Salir de la función si se alcanza el límite
      } else {
        const agregarOtro_3 = document.getElementById('agregarOtro_3');
        window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio?newCar=true';
     }  
    },
    sendParams() {
      var params = {
        // token: this.token,
        conditions: this.conditions,
        rut: this.propietarioData.rutPropietario
      };
      this.$emit("getToken", params);
    },
    async obtenerToken() {
      // console.log(this.patentes);
      let algunaPatenteConSOAP = false;

      for (const patente of this.patentes) {
          const patenteAprobada = await ShieldService.consultasoapAprobado(patente.patenteNumber);
          if (patenteAprobada.status === 'ok') {
              algunaPatenteConSOAP = true;
              swal({
                  title: "Patente registrada",
                  text: "La patente " + patente.patenteNumber + " ya cuenta con un seguro SOAP", 
                  icon: "info",
                  button: "Ok",
              });
              // Si quieres detener la iteración después de encontrar una patente con SOAP,
              // puedes agregar un break aquí
              break;
          }
      }

      if (!algunaPatenteConSOAP) {
          this.planId = localStorage.getItem('planId');
          this.pagar = true;
      }
    },
    async logincenco() {
      if (this.$refs.form.validate()) {
        this.loadLogin = true
        this.tokenpartner = await BodumService.getTokenPartner();
        // console.log("token partner: ", this.tokenpartner)
        this.dialog = true;
        this.respuestaLogin = await BodumService.loginsocio(this.tokenpartner, this.rutCliente, this.contrasenaCliente);
        // console.log("token socio: ", this.respuestaLogin)
        // console.log("usuario: ", this.rutCliente)
        // console.log("contraseña: ", this.contrasenaCliente)
        if (this.respuestaLogin.code == 'USER_TMP_PWD' || this.respuestaLogin.code == 'OK') {
          this.loadPuntos = true
          this.tokensocio = this.respuestaLogin.token;
          this.puntosclientes = await BodumService.consultaPuntos(this.tokenpartner, this.rutCliente);
          this.loadPuntos = false;
          if (this.switchFormaPago == 'false' && this.tokensocio != null) {
            var datos = {
              name: this.puntosclientes.nombre,
              surname: this.puntosclientes.apellidoPaterno,
              points: this.puntosclientes.puntos,
              pointsCenco: this.patentes[0].pricePuntos,
            }
            this.clienteCenco.push(datos)
            this.generaropt = await BodumService.GenerarOPT(this.tokenpartner, this.tokensocio);
            // console.log(this.generaropt)
            if (this.generaropt.status == "ok") {
              this.dialogOpt = true
            }
          }
          this.loadLogin = false
          swal({
            title: "Login Exitoso",
            text: "Ahora puede continuar con la compra!",
            icon: "success",
            button: "Ok",
          });
          this.status = true
          this.dialog = false;

        } else {
          this.loadLogin = false
          swal({
            title: "Credenciales inválidas",
            text: "",
            icon: "error",
            button: "Ok",
          });
        }
      }

    },
    async canjearpuntos() {
      this.loadCanje = true
      // console.log(this.externalId)
      this.validarcanje = await BodumService.PagoPuntos(this.tokenpartner, this.tokensocio, this.codigoOpt, this.patentes[0].pricePuntos, this.externalId, this.keygen);
      // console.log(this.validarcanje);

      if (this.$refs.form.validate()) {
        if (this.validarcanje.status == 'ok') {
          const query = this.$route.query;
          var res = await ShieldService.pagoCencosud(this.keygen, this.typePlan)
          // console.log(res)
          this.loadCanje = false
          swal({
            title: "Canje Exitoso",
            text: "La compra del seguro se ha completado",
            icon: "success",
            button: "Ok",
          });
          window.location.href = res;
        } else {
          this.loadCanje = false
          swal({
            title: "Canje inválido",
            text: "",
            icon: "error",
            button: "Ok",
          });
        }
      }
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async changeRut(rut) {
      this.propietarioData.rutPropietario = RutUtils.formatRut(rut);
      this.newRut = RutUtils.formatRut(rut);

      await ShieldService.updatePagador(this.keygen, this.propietarioData);
      this.sendParams();
    },
  },
};
</script>


<style lang="css" scoped>
.titulo {
  color: #1269B0;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0px;
  font-family: 'Nunito Sans', sans-serif;
  text-align: center;
  line-height: 1.2;
}

.subtitulo {
  font-family: 'Nunito Sans', sans-serif;
  width: 280px;
  text-align: center;
  margin: 15px auto auto;
  line-height: 1.2;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageCenco {
  background-image: url("~@/assets/images/CencoImage.png");
  background-position: center;
  background-size: cover;
  display: flex;
}

.login {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 0.3rem;
  background-color: rgb(19, 65, 105);
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 0px 1.5rem;
  cursor: pointer;
  color: white;
  font-weight: 600;
  font-family: 'Nunito Sans', sans-serif;
  margin: auto;
}

#app>div.v-dialog__content.v-dialog__content--active>div>div>div>div>div:nth-child(1)>button {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  background-color: rgb(19, 65, 105);
}

.precio {
  height: 40px;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 30px;
  letter-spacing: 0.53px;
  color: #1269B0;
  opacity: 1;
}

.patenteNumber {
  width: 138px;
  height: 40px;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 35px;
  letter-spacing: 0.53px;
  color: #1269B0;
  text-transform: uppercase;
  opacity: 1;
}

.valor {
  background-color: #4F74E3;
  color: #FFF;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.condicion {
  width: 56px;
  height: 21px;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0.26px;
  color: #1269B0;
  text-transform: uppercase;
  opacity: 1;
}

.patente {
  width: 76px;
  height: 21px;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 17px;
  letter-spacing: 0.26px;
  color: #1269B0;
  text-transform: uppercase;
  opacity: 1;
}

.webpay {
  width: 80%;
  height: 206px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.cenco {
  width: 80%;
  height: 206px;
  background: #753783 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.rectangulo {
  box-shadow: 0px 0px 6px #00000029;
  max-width: 420px;
  opacity: 1;
  margin: auto;
  border-radius: 10px;

}

.hrStyle {
  width: 147px;
  height: 0px;
  border: 1px solid #D0D0D0;
  opacity: 1;
  margin: auto;
}

.question {
  color: #1269B0;
  font-family: 'Nunito Sans', sans-serif;
  margin-right: 1%;
  font-size: 20px;
}

.button1 {
  width: 270px;
  height: 50px !important;
  background-color: #EF7C00 !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
  margin-left: 1%;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}

.button2 {
  width: 270px;
  height: 50px !important;
  background-color: #EF7C00 !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
  margin-left: 1%;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}

.finalBox {
  margin-left: -4%;
}

.title {
  color: #1269B0;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: -0.36px !important;
}

.labelName {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0px;
  color: #1269B0;
  opacity: 1;
  font-weight: bold;
  margin-left: -72%;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: -0.2px;
  line-height: 38.5px;
}

.checkbox {
  max-width: 540px;
  margin: auto;
  color: #1269B0;
  font-family: 'Nunito Sans', sans-serif;
  letter-spacing: -0.2px;
  display: flex;
  text-align: justify;
  font-size: 13px;
  margin-bottom: -3%;
}

.payTitleImage {
  color: #2D387C;
  display: flex;
  justify-content: center;
}

.payButtons {
  display: flex;
  justify-content: center;
}

.pay {
  background-color: #EFEFEF;
  /* margin-left: -20%; */
  /* max-width: x100%; */
  width: 100vw;
  position: absolute;
  left: 50%;
  margin-left: -200px;
}

.payTitle {
  font-size: 24px;
  margin-top: 0.5%;
}

.payImage {
  margin-left: 2%;
}

.btnBack {
  width: 270px;
  height: 50px !important;
  background-color: #EF7C00 !important;
  border-radius: 8px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: bold;
}

.btnPay {
  width: 260px;
  height: 50px !important;
  background-color: #EF7C00 !important;
  border-radius: 8px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 6%;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}

.tableHeaders {
  background-color: #2D387C;
  color: white !important;
  border: 2px solid #2D387C;
}

.text-center {
  text-align: center;
}

.table {
  border: 2px solid #2D387C;
  margin-top: -2%;
  margin-bottom: -1%;
}

.btnClosePopUp {
  background-color: #EF7C00;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 30%;
  margin-top: -2%;
  font-family: 'Nunito Sans', sans-serif;
}

.btnClosePopUp2 {
  height: 40px !important;
  background-color: #EF7C00;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 100%;
  margin-top: -2%;
  font-family: 'Nunito Sans', sans-serif;
}

.btnConfirmPopUp {
  background-color: #EF7C00;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 30%;
  margin-top: -2%;
  font-family: 'Nunito Sans', sans-serif;
}

.btnConfirmPopUp2 {
  height: 40px !important;
  background-color: #EF7C00;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 100%;
  margin-top: -2%;
  font-family: 'Nunito Sans', sans-serif;
}

.title1 {
  color: #1269B0;
  font-size: 22px;
  font-family: 'Nunito Sans', sans-serif;
}

.title2 {
  color: #1269B0;
  margin-left: 1%;
  margin-right: 1%;
  font-family: 'Nunito Sans', sans-serif;
}

.titleBox {
  width: 100%;
  text-align: center;
}

.titleBoxMobile {
  width: 90%;
  margin: auto;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 3%;
}

.btnBoxMobile {
  display: block;
  width: 70%;
}


.patentFieldView {
  width: 300px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: auto;
  text-transform: uppercase;
}

.patentFieldView2 {
  width: 100%;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  /* margin-left: 41%; */
  text-transform: uppercase;
}

.priceBox {
  display: flex;
  margin-top: -2%;
}

.priceBox2 {
  display: flex;
  margin-top: -2%;
  width: 90%;
  margin-left: 4%;
}
</style>

<style lang="css">
.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  background-color: #2D387C;
  color: white !important;
}

.space {
  margin-bottom: 10% !important;
}

.trash {
  color: red !important;
}
</style>