<template>
    <v-container>

        <br>
        <br>

        <div :class="image">
            <v-img contain max-height="100" :src="infoImage" max-width="100" style="margin:auto;" />
        </div>

        <br>
        <br>

        <div :class="image">
            <div class="text-center">
                <label
                    style="font-family: 'Nunito Sans', sans-serif; font-style: normal; font-weight: 400; font-size: 20px; line-height: 20px; text-align: center; color: #1269B0; opacity: 0.75;">
                    Tu transacción a sido procesada
                </label>

                <br>
                <br>

                <label
                    style="font-family: 'Nunito Sans', sans-serif; font-style: normal; font-weight: 400; font-size: 15px; line-height: 20px; text-align: center; color: #1269B0; opacity: 0.75;">
                    Dentro de las próximas horas podrás visualizar tu póliza en tu email asociado.
                </label>
            </div>
        </div>

        <br>
        <br>

        <div class="text-center">
            <v-btn style="width: 310px; height: 64px; background: #EF7C00 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #767676; border: 1px solid #DEDEDE;
                    border-radius: 30px; opacity: 1; text-align: center; font: normal normal normal 22px/27px Arial; letter-spacing: 0.66px; color: white; text-transform: 
                    uppercase; opacity: 1; font-family: 'Nunito Sans', sans-serif;" @click="returnPage()">
                <v-icon color="white" dark left>
                    mdi-arrow-left
                </v-icon>
                Volver
            </v-btn>
        </div>

        <br>

    </v-container>
</template>

<script>
import infoImage from "@/assets/images/check.png";

export default {
    data() {
        return {
            image: null,
            infoImage,
            screen: window.screen.width,
            path: null,
        }
    },
    async mounted() {
        const query = this.$route.query;
        this.path = query.path
        if (this.screen > 800) {
            this.image = 'image'
        } else {
            this.image = 'image2'
        }
    },
    methods: {
        returnPage() {
            window.location.href = process.env.VUE_APP_DOMINIO + this.path + '/inicio'
        }
    }

}
</script>

<style lang="css" scoped>
.v-alert {
    position: fixed;
    left: 50%;
    bottom: 50px;
    transform: translate(-50%, 100%);
    margin: 0 auto;
}

.image {
    width: 30%;
    margin: auto;
}

.image2 {
    width: 100%;
    margin: auto;
}
</style>
